import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './plugins/axios'
import './plugins/vee'
import './plugins/vuetoastification'
import configs from '@/configs'

import { getPermissions, errorFunction, filters } from '@/utils'
Vue.filter('dateFilter', filters.dateFilter)
Vue.filter('floatFilter', filters.floatFilter)
Vue.filter('cpfCnpjFilter', filters.cpfCnpjFilter)

Vue.prototype.$fnError = errorFunction

Vue.config.productionTip = false

Vue.prototype.$getPermissions = getPermissions

Vue.prototype.$ipUser = `https://api.${configs.env === 'homol' ? 'homol.' : ''}user.znaptech.com/`
Vue.prototype.$ipClient = `https://api.${configs.env === 'homol' ? 'homol.' : ''}client.znaptech.com/`
Vue.prototype.$ipSecurity = `https://api.${configs.env === 'homol' ? 'homol.' : ''}security.znaptech.com/`
Vue.prototype.$ipEvent = `https://api.${configs.env === 'homol' ? 'homol.' : ''}event.znaptech.com/`
Vue.prototype.$ipAccount = `https://api.${configs.env === 'homol' ? 'homol.' : ''}account.znaptech.com/`
Vue.prototype.$ipCustomer = `https://api.${configs.env === 'homol' ? 'homol.' : ''}customer.znaptech.com/`
Vue.prototype.$ipOrganization = `https://api.${configs.env === 'homol' ? 'homol.' : ''}organization.znaptech.com/`
Vue.prototype.$ipUnit = `https://api.${configs.env === 'homol' ? 'homol.' : ''}unit.znaptech.com/`
Vue.prototype.$ipMessage = `https://api.${configs.env === 'homol' ? 'homol.' : ''}message.znaptech.com/`
Vue.prototype.$ipIntegration = `https://api.${configs.env === 'homol' ? 'homol.' : ''}integration.znaptech.com/`
Vue.prototype.$vuetify = vuetify

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
