export default function setModule(request, defaultModule) {
    // defaultModule => "ZNAP_SECURITY"

    let endpoint = request.url
    let module = ""

    switch (true) {
        case (endpoint.indexOf('user/menu')):
            module = defaultModule
            break
        case (endpoint.indexOf('user/hash/login')):
            module = defaultModule
            break
        case (endpoint.indexOf('user/list/') !== -1):
            module = defaultModule
            break
        case (endpoint.indexOf('client-module/list/client')):
            module = defaultModule
            break
        case (endpoint.indexOf('event-type/list') !== -1):
            module = defaultModule
            break
        default:
            module = defaultModule
    }

    return module
}