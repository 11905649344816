"use strict";

import pt from 'vuetify/es5/locale/pt'

export default {
    ...pt,

    // USER PROFILE
    name: 'Nome',
    password: 'Senha',
    changePassword: 'Alterar senha',
    oldPassword: 'Senha antiga',
    newPassword: 'Nova senha',
    cancel: 'Cancelar',
    userInformation: 'Informações do usuário',
    userInformationxs: 'Perfil',
    change: 'Alterar',
    add: 'Adicionar',
    remove: 'Remover',
    primaryEmail: 'E-mail principal',
    addAlternateEmail: 'Adicionar e-mail alternativo',
    addAlternateEmailxs: 'E-mail alternativo',
    phone: 'Telefone',
    primaryPhone: 'Telefone principal',
    addAlternatePhone: 'Adicionar telefone alternativo',
    addAlternatePhonexs: 'Tel alternativo',
    extension: 'Ramal',
    smsCellphone: 'Preencha um celular para receber SMS',
    smsCellphonexs: 'Celular para SMS',
    chooseRegisteredPhone: 'Ou escolha um telefone cadastrado',
    passwordExpirationDate: 'Data de expiração da senha',
    lastUpdate: 'Última atualização',
    saveChanges: 'Salvar alterações',
    save: 'Salvar',

    // TABLE
    export: 'Exportar planilha',
    import: 'Importar planilha',
    searchLabel: 'Pesquisar',
    reload: 'Recarregar',
    massDeleteBtn: 'Deletar itens selecionados',
    crudCreateBtn: 'Criar',
    crudDeleteBtn: 'Deletar',
    crudUpdateBtn: 'Editar',
    editingTableSnack: 'Editando linha da tabela',
    cancelTableSnack: 'Edição cancelada',
    saveTableSnack: 'Edição salva',
    toastError: 'Ops...!',
    toastSuccess: 'Êxito!',
    deleteSure: 'Você tem certeza que deseja deletar este item?',
    client: {
        title: 'Clientes',
        dialog: {
            create: 'Criar cliente',
            delete: 'Deletar cliente',
            massDelete: 'Você tem certeza que deseja excluir estes clientes?',
            update: 'Atualizar cliente',
        }
    },
    module: {
        title: 'Módulos',
        dialog: {
            create: 'Criar módulos',
            delete: 'Deletar módulo',
            massDelete: 'Você tem certeza que deseja excluir estes módulos?',
            update: 'Atualizar módulo',
        }
    },
    'client-class': {
        title: 'Classe do cliente',
        dialog: {
            create: 'Criar classe de cliente',
            delete: 'Deletar classe de cliente',
            massDelete: 'Você tem certeza que deseja excluir estas classes de cliente?',
            update: 'Atualizar classe de cliente',
        }
    },
    'client-module': {
        title: 'Módulos do cliente',
        dialog: {
            create: 'Relacionar módulos e cliente',
            delete: 'Deletar relação',
            massDelete: 'Você tem certeza que deseja excluir estas relações?',
            update: 'Atualizar relação de módulo e cliente',
        }
    },
    group: {
        title: 'Grupos de usuários',
        dialog: {
            create: 'Criar grupos de usuário',
            delete: 'Deletar grupo de usuário',
            massDelete: 'Você tem certeza que deseja excluir estes grupos de usuários?',
            update: 'Atualizar grupo de usuário',
        }
    },
    transaction: {
        title: 'Transações',
        dialog: {
            create: 'Criar transação',
            delete: 'Deletar transação',
            massDelete: 'Você tem certeza que deseja excluir estas transações?',
            update: 'Atualizar transação',
        }
    },
    'group-transaction': {
        title: 'Perfil de acesso',
        dialog: {
            create: 'Criar perfil de acesso',
            delete: 'Deletar perfil de acesso',
            massDelete: 'Você tem certeza que deseja excluir estes perfis de acesso?',
            update: 'Atualizar perfil de acesso',
        }
    },
    user: {
        title: 'Usuários',
        dialog: {
            create: 'Criar usuário',
            delete: 'Desativar usuário',
            massDelete: 'Você tem certeza que deseja desativar estes usuários?',
            update: 'Atualizar usuário',
        }
    },
    'user-group': {
        title: 'Perfil de usuário',
        dialog: {
            create: 'Criar perfil de usuário',
            delete: 'Deletar perfil de usuário',
            massDelete: 'Você tem certeza que deseja excluir estes perfis de usuário?',
            update: 'Atualizar perfil de usuário',
        }
    },
};